import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/(site)/geo-blocked/page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/(site)/gifs/[slug]/desktop-pagination.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/(site)/gifs/[slug]/gif-hover.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/(site)/gifs/[slug]/menu/desktop/close-on-esc.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/(site)/gifs/[slug]/menu/desktop/gif-embed-modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/(site)/gifs/[slug]/menu/desktop/gif-info-modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CopyButton","DownloadToggle"] */ "/app/app/nextjs/app/(site)/gifs/[slug]/menu/desktop/vertical-menu-toggles.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/(site)/gifs/[slug]/menu/local-favorite.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/(site)/gifs/[slug]/menu/more-info.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/(site)/gifs/[slug]/menu/open-edit-panel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/(site)/gifs/[slug]/menu/report-gif.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/(site)/gifs/[slug]/menu/source.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/(site)/gifs/[slug]/menu/tags.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/(site)/gifs/[slug]/related-grid.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/(site)/gifs/[slug]/sidebar/description.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/(site)/gifs/[slug]/sidebar/panel-toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/(site)/header-init.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/(site)/search/[term]/filters/open-in-curation-station.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/components/detail/gif-panel-context-manager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/components/detail/share-portal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClickToShowModal"] */ "/app/app/nextjs/app/components/modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/components/responsive-gif.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/nextjs/components/ads/medium-rectangle.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/nextjs/components/ads/top-leaderboard.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/nextjs/components/attribution.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/components/favorite-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/context/ads-manager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Gif"] */ "/app/node_modules/@giphy/react-components/dist/esm/index.js");
