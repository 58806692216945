'use client'
import { cn } from '@/util/cn'
import { useEffect, useRef, useState } from 'react'

type Props = { description: string }
const truncateAt = 70
const descriptionClassName = 'text-giphyLightGrey max-w-52 text-sm'
const Description = ({ description }: Props) => {
    const [showDisclosure, setDisclosure] = useState(false)
    const [needsClamp, setNeedsClamp] = useState(description.length > truncateAt)
    const desc = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (desc.current) {
            setNeedsClamp(desc.current.scrollHeight > desc.current.clientHeight)
        }
    }, [setNeedsClamp, description])
    if (description.length > truncateAt) {
        return (
            <div className={descriptionClassName}>
                <div className={`${showDisclosure ? '' : 'line-clamp-2'}`} ref={desc}>
                    {showDisclosure ? description : description}
                </div>
                <div
                    onClick={() => setDisclosure(!showDisclosure)}
                    className={cn(
                        'font-semibold opacity-0 transition-opacity',
                        needsClamp && 'opacity-1 cursor-pointer'
                    )}
                >
                    {showDisclosure ? 'Read Less ▴' : 'Read More ▾'}
                </div>
            </div>
        )
    }
    return <div className={descriptionClassName}>{description}</div>
}
export default Description
