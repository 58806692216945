'use client'
import { GifPanelContext } from '@/app/components/detail/gif-panel-context-manager'
import { cn } from '@/util/cn'
import { IGif } from '@giphy/js-types'
import { useContext, useEffect, useState } from 'react'
import GifMessagePanel from './menu/desktop/message-panel'
import SharePanel from './menu/desktop/share-panel'
import { desktopGifWidth } from './util'

type Props = { gif: IGif; className?: string; gifHeight?: number; gifWidth: number; children?: React.ReactNode }

const GifWithOverlays = ({ gif, className, gifHeight, gifWidth, children }: Props) => {
    const { panel, setPanel } = useContext(GifPanelContext)
    const [hovered, setHovered] = useState(false)
    useEffect(() => {
        if (hovered) {
            if (panel === 'none') setPanel('share')
        } else {
            if (panel === 'share') setPanel('none')
        }
    }, [hovered, panel, setPanel])
    return (
        <div
            className={cn('relative flex justify-center overflow-hidden', className)}
            style={{ width: desktopGifWidth, height: gifHeight }}
            onMouseEnter={() => {
                setHovered(true)
            }}
            onMouseLeave={() => {
                setHovered(false)
            }}
        >
            {children}
            <div className="absolute bottom-0 top-0" style={{ width: gifWidth }}>
                <SharePanel gif={gif} width={gifWidth} />
                <GifMessagePanel />
            </div>
        </div>
    )
}
export default GifWithOverlays
