'use client'
import { GifPanelContext } from '@/app/components/detail/gif-panel-context-manager'
import { cn } from '@/util/cn'
import { IGif } from '@giphy/js-types'
import { useContext, useState } from 'react'
type Props = { gif: IGif; width: number }

const SharePanel = ({ gif, width }: Props) => {
    const { panel } = useContext(GifPanelContext)
    const [isGrabbing, setGrabbing] = useState(false)
    return panel === 'share' ? (
        <div
            className={cn('pointer-events-auto absolute inset-0', isGrabbing ? 'cursor-grabbing' : 'cursor-grab')}
            onMouseDown={() => setGrabbing(true)}
            onMouseUp={() => setGrabbing(false)}
        >
            <div className="absolute bottom-0 left-0 right-0 h-10">
                <div
                    className="absolute bottom-0 left-0 right-0 top-0 h-10 opacity-50"
                    style={{
                        background:
                            "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4AQMAAACSSKldAAAABlBMVEUhIiIWFhYoSqvJAAAAGElEQVQY02MAAv7///8PWxqIPwDZw5UGABtgwz2xhFKxAAAAAElFTkSuQmCC') 0 0",
                        backgroundSize: '27px',
                    }}
                ></div>
                <div className="relative flex h-full items-center justify-center text-center font-bold">
                    Drag &amp; Drop {width > 300 ? 'this GIF' : ''} anywhere!
                </div>
            </div>
            <div className="absolute inset-0 block opacity-0">
                <img src={gif.images.original.url} alt="" width="100%" height="100%" />
            </div>
        </div>
    ) : null
}

export default SharePanel
