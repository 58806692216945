'use client'
import Loader from '@/app/components/loader'
import { SearchTypeCopy, SearchTypeToFetchType } from '@/app/util/search-util'
import { useGiphyFetch } from '@/app/util/use-gf'
import Grid from '@/components/grid/grid'
import VideoGrid from '@/components/grid/video-grid'
import { SectionHeader } from '@/components/sections'
import Toggle from '@/components/toggle'
import UAParserContext from '@/context/ua-parser'
import { relativeGifClick } from '@/util/url'
import { GifID, IGif } from '@giphy/js-types'
import { setGADataLayer } from 'analytics'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { SearchType } from '../../search/types'
import { GifsContext } from '../gifs-context'
type Props = {
    gif: IGif
}
const RelatedGrid = ({ gif }: Props) => {
    const id = gif.id as string
    const { deviceType } = useContext(UAParserContext)
    let width
    let columns
    if (deviceType === 'desktop') {
        width = 1040
        columns = 4
    }
    const gf = useGiphyFetch()
    // save these to report the index of the related gif clicked
    const ids = useRef<GifID[]>([])
    const contentTypes = ['gifs', 'stickers', 'clips'] as const
    const [isTransitioning, setTransitioning] = useState(false)
    const [type, setType] = useState<SearchType>(gif.is_sticker ? 'stickers' : 'gifs')
    const doFetch = useCallback(
        async (offset: number) => {
            const result = await gf.related(id, { offset, rating: 'pg-13', type: SearchTypeToFetchType[type] })
            ids.current = result.data.map((gif) => gif.id)
            return result
        },
        [gf, id, type]
    )
    const { relatedGifs: _relatedGifs } = useContext(GifsContext)
    const relatedGifs = useMemo(() => {
        // filter out the current id
        return _relatedGifs.filter((gif) => gif.id !== id)
    }, [_relatedGifs, id])

    // Related Gif Nav is gonna fetch the first few related gifs
    return relatedGifs.length > 0 ? (
        <section>
            <SectionHeader
                label={
                    <div className="hidden text-lg md:block">
                        <div className="text-giphyWhite leading-[1.45rem]">{`Related ${SearchTypeCopy[type]}`}</div>
                        <div className="text-giphyLightCharcoal font-normal">{gif.title}</div>
                    </div>
                }
                className="mb-1 items-end"
            >
                <Toggle
                    onClick={() => {}}
                    className="mt-2 w-full md:mt-0 md:min-w-[240px]"
                    onTransitionEnd={(index: number) => {
                        const nextType = contentTypes[index]
                        setType(nextType)
                        setTransitioning(false)
                    }}
                    options={[{ label: 'GIFs' }, { label: 'Stickers' }, { label: 'Clips' }]}
                    index={contentTypes.indexOf(type)}
                />
            </SectionHeader>
            <div className="min-h-[1500px]">
                {isTransitioning ? (
                    <Loader className="animate-fade-in" />
                ) : type === 'clips' ? (
                    <VideoGrid
                        gaTrackingUrl={(page) => `/related/${id}/clips/page/${page}`}
                        gridWidth={width}
                        key={type}
                        columns={columns}
                        fetchGifs={doFetch}
                    />
                ) : (
                    <Grid
                        initialGifs={type === 'gifs' ? relatedGifs : []}
                        width={width}
                        key={type}
                        columns={columns}
                        fetchGifs={doFetch}
                        onGifClick={(gif, event) => {
                            const contentType = gif.is_sticker ? 'sticker' : 'gif'
                            const data = {
                                event: 'related_content_click',
                                options: {
                                    eventInfo: {
                                        navElement: 'related_content',
                                        navSubelement: 'related_' + contentType,
                                        link: gif.url,
                                    },
                                    content: {
                                        contentId: gif.id,
                                        contentType,
                                        contentIndex: ids.current.indexOf(gif.id),
                                    },
                                },
                            }
                            setGADataLayer(data)
                            relativeGifClick(gif, event)
                        }}
                    />
                )}
            </div>
        </section>
    ) : null
}
export default RelatedGrid
