'use client'
import ViewIcon from '@/app/icons/view'
import Button from '@/components/button'

type Props = {
    label: string
    href: string
}

const OpenInCurationStation = ({ label, href }: Props) => {
    return (
        <Button
            className="br-4px flex h-[40px] max-w-[133px] cursor-pointer items-center gap-2 rounded"
            href={href}
            isExternal
            target="_blank"
        >
            <div>{label}</div>
            <ViewIcon className="size-4" />
        </Button>
    )
}

export default OpenInCurationStation
