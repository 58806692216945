'use client'
import { cn } from '@/util/cn'
import { IGif } from '@giphy/js-types'
import { setGADataLayer } from 'analytics'
import Link from 'next/link'
import { useState } from 'react'
const pillClassName =
    'bg-gradient-grey flex shrink-0 cursor-pointer flex-nowrap gap-px rounded-3xl px-4 py-3.5 items-center'
type Props = { gif: IGif }
const maxCharacters = 80
const Tags = ({ gif }: Props) => {
    let needsShowMore = false
    let chars = 0
    let truncateTagsAtIndex
    gif.tags.some((tag, index) => {
        chars += tag.length + 5 // add 5 for the space between tags
        if (chars > maxCharacters) {
            truncateTagsAtIndex = index
            needsShowMore = true
            return true
        }
    })
    const [showMore, setShowMore] = useState(false)
    const tags = showMore ? gif.tags : [...gif.tags].slice(0, truncateTagsAtIndex)
    return (
        <div className="mb-5 flex gap-1.5">
            <div className={`group flex flex-wrap gap-1.5`}>
                {tags.map((tag) => (
                    <Link
                        href={`/explore/${encodeURIComponent(tag.replace(/ /g, '-'))}`}
                        key={tag}
                        className={cn(
                            pillClassName,
                            'transition-opacity duration-200',
                            'group-hover:[&:not(:hover)]:opacity-50'
                        )}
                        onClick={() => {
                            setGADataLayer({
                                event: 'search',
                                options: {
                                    search_type: 'detail-tags',
                                    search_term: tag,
                                },
                            })
                        }}
                    >
                        <span className="text-giphyLightGrey translate-y-[1px]">#</span>
                        <span className="font-bold">{tag}</span>
                    </Link>
                ))}
                {needsShowMore && !showMore ? (
                    <div onClick={() => setShowMore(true)}>
                        <div className={cn(pillClassName, 'cursor-pointer')}>...</div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}
export default Tags
