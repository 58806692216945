'use client'
import { GifPanelContext, PanelType } from '@/app/components/detail/gif-panel-context-manager'
import { ReactNode, useContext } from 'react'

type Props = { className?: string; children: ReactNode; type: PanelType }

export const PanelToggle = ({ className, children, type }: Props) => {
    const { setPanel, panel } = useContext(GifPanelContext)
    return (
        <>
            <div
                className={className}
                onClick={() => {
                    setPanel(panel === type ? 'none' : type)
                }}
            >
                {children}
            </div>
        </>
    )
}

export default PanelToggle
