'use client'
import { useContext, useEffect } from 'react'
import { GifPanelContext } from '../../../../../components/detail/gif-panel-context-manager'
import { useKeyPress } from 'react-use'

const CloseOnEsc = () => {
    const { setPanel } = useContext(GifPanelContext)
    const isEsc = useKeyPress('Escape')
    useEffect(() => {
        if (isEsc) {
            setPanel('none')
        }
    }, [isEsc, setPanel])
    return null
}

export default CloseOnEsc
