'use client'
import { GifPanelContext } from '@/app/components/detail/gif-panel-context-manager'
import CloseIcon from '@/app/icons/close'
import Button from '@/components/button'
import { cn } from '@/util/cn'
import { IGif } from '@giphy/js-types'
import { useContext } from 'react'
import { createPortal } from 'react-dom'
import { modalBackgroundClassName, modalBoxClassName } from './class-names'

export const formatSize = (size = 0, decimals = 0) => {
    if (size === 0) return '0 Bytes'

    const k = 1000 // or 1024 for binary
    const dm = decimals + 1 || 3
    const sizes = ['Bytes', 'KB', 'MB', 'GB']
    const i = Math.floor(Math.log(size) / Math.log(k))
    const filesize = size / Math.pow(k, i)
    return parseFloat(filesize.toFixed(i < 2 ? 0 : dm)) + ' ' + sizes[i]
}

type ExtraPropsGif = {
    create_datetime: string
    // frames: number
} & IGif

const extraProps = (gif: IGif): gif is ExtraPropsGif => {
    return (gif as ExtraPropsGif).create_datetime !== undefined
}

type Props = { gif: IGif }
const columnClassName = 'flex flex-col gap-6'

const GifInfoOverlay = ({ gif }: Props) => {
    const { panel, setPanel } = useContext(GifPanelContext)
    const { width, height, size } = gif.images.original
    // @ts-expect-error TODO: frames
    const { frames } = gif.images.original
    const showLastCol = extraProps(gif) || gif.username
    return panel === 'info'
        ? createPortal(
              <div
                  className={modalBackgroundClassName}
                  onClick={() => {
                      setPanel('none')
                  }}
              >
                  <div
                      className={cn(modalBoxClassName, 'min-w-[560px] gap-8 px-6 py-4')}
                      onClick={(e) => {
                          e.stopPropagation()
                      }}
                  >
                      <CloseIcon
                          height={18}
                          className="fill-giphyWhite absolute right-4 top-4 cursor-pointer"
                          onClick={() => {
                              setPanel('none')
                          }}
                      />
                      <h2 className="mt-6 text-center text-2xl">{gif.title}</h2>
                      <div
                          className={cn(
                              'flex justify-between p-3',
                              '[&_label]:text-giphyLightCharcoal text-2xl font-bold [&_label]:block [&_label]:text-lg'
                          )}
                      >
                          <div className={columnClassName}>
                              <div>
                                  <label>Dimensions</label>
                                  <span>
                                      {width} x {height} px
                                  </span>
                              </div>

                              {size && (
                                  <div>
                                      <label>Size</label>
                                      <span>{formatSize(parseFloat(size))}</span>
                                  </div>
                              )}
                          </div>
                          <div className={columnClassName}>
                              <div>
                                  <label>Frames</label>
                                  <span>{frames || ''}</span>
                              </div>
                              <div>
                                  <label>Rating</label>
                                  <span>{(gif.rating || 'unrated').toUpperCase()}</span>
                              </div>
                          </div>
                          {showLastCol && (
                              <div className={columnClassName}>
                                  {gif.username && (
                                      <div>
                                          <label>Uploader</label>
                                          <div className="max-w-64 overflow-hidden text-ellipsis">@{gif.username}</div>
                                      </div>
                                  )}
                                  {extraProps(gif) && (
                                      <div>
                                          <label>Upload Date</label>
                                          <span>{new Date(gif.create_datetime).toLocaleDateString()}</span>
                                      </div>
                                  )}
                              </div>
                          )}
                      </div>
                      <Button onClick={() => setPanel('none')} className="mx-auto mb-2 cursor-pointer px-16">
                          Close
                      </Button>
                  </div>
              </div>,
              document.body,
              'gif-info-panel'
          )
        : null
}
export default GifInfoOverlay
